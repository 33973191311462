import React from 'react';

import {
  FCWithChildren,
  serverTimelineTypeToClientTimelineString,
  serverToClientPricing,
} from '@wix/challenges-web-library';
import { MemberChallenge } from '@wix/ambassador-challenges-v1-challenge/types';
import userTypeHandlers from '../../../../contexts/User/helpers/userTypeHandlers';

import {
  useEnvironment,
  useExperiments,
  useTranslation,
} from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';
import settingsParams from '../../settingsParams';

import { Button, ButtonPriority, ButtonSize } from 'wix-ui-tpa/cssVars';
import { ChallengeBadge } from '../../Badge';
import { ChallengeCardSubtitle } from './ChallengeCardSubtitle';

import {
  classes as classesGrid,
  st as stGrid,
} from './ChallengeCardAsGrid.st.css';
import {
  classes as classesSide,
  st as stSide,
} from './ChallengeCardAsSideBySide.st.css';
import { ILayoutType } from '../../Settings/challengeSettings/challengeSettings.types';
import { useChallengesListData } from '../../../../contexts/ChallengesListDataProvider/ChallengesListContext';
import { getMediaPreview } from '../../../../selectors/media';
import { useCSSPBStyle } from '../../../../hooks/useCSSPBStyle';
import { isPrivateChallenge } from '../../../../selectors/isPrivateChallenge';
import { isFlexible } from '../../../../selectors/isFlexible';
import { isSpecific } from '../../../../selectors/isSpecific';
import TextWithDirection from '../../../../components-shared/TextWithDirection';
import { getProgramAltImageText } from '../../../../selectors/getProgramImageAltText';
import { Challenges } from '../../../../editor/types/Experiments';

interface IChallengeCard {
  memberChallenge: MemberChallenge;
  buttonState: 'default' | 'hover';
  userPaidPlans: any;
  goToPage(params: any): void;
  isFullWidth: boolean;
  cardIndex: number;
}

function getLoadingStrategy(
  isMobile: boolean,
  index: number,
): 'lazy' | 'eager' {
  if (isMobile) {
    return index > 1 ? 'lazy' : 'eager';
  }

  return index > 6 ? 'lazy' : 'eager';
}

export const ChallengeCard: FCWithChildren<IChallengeCard> = (props) => {
  const { t } = useTranslation();
  const settings = useSettings();
  const { language, isMobile } = useEnvironment();
  const { getProgramLink } = useChallengesListData();
  const { experiments } = useExperiments();
  const cssPBEnabled = experiments.enabled('specs.programs.OOIStyleBP');
  const getStyle = useCSSPBStyle();
  const isGridLayout =
    settings.get(settingsParams.layoutType) === ILayoutType.Grid;
  const st = isGridLayout ? stGrid : stSide;
  const isExtendedLimitsEnabled = experiments.enabled(
    Challenges.enableExtendedLimits,
  );

  const classes = isGridLayout ? classesGrid : classesSide;
  const cardRef = React.useRef<HTMLDivElement>();

  const {
    memberChallenge,
    userPaidPlans,
    goToPage,
    buttonState,
    isFullWidth,
    cardIndex,
  } = props;
  const { challenge, summary } = memberChallenge;
  const isDurationMeaningful: boolean =
    isFlexible(memberChallenge?.challenge) ||
    isSpecific(memberChallenge?.challenge) ||
    !!memberChallenge?.challenge?.settings?.timelineType?.selfPaced?.duration;
  const displayParams = cssPBEnabled
    ? {
        image: true,
        duration: isDurationMeaningful,
        participants: true,
        divider: true,
        price: true,
      }
    : {
        image: settings.get(settingsParams.displayImage),
        duration:
          settings.get(settingsParams.displayDuration) && isDurationMeaningful,
        participants: settings.get(settingsParams.displayParticipants),
        divider: settings.get(settingsParams.displayDivider),
        price: settings.get(settingsParams.displayPrice),
      };

  const title = challenge.settings.description.title;
  const isTitleExceedLimit = title.length > 50 && isExtendedLimitsEnabled;
  const TitleTag = settings.get(settingsParams.challengeNameTag) || 'h2';

  const participantState = summary?.participation?.state;

  const showParticipantButton = userTypeHandlers.isTouchedChallenge(
    participantState as any,
  );

  const durationString = serverTimelineTypeToClientTimelineString(
    challenge.settings.timelineType as any,
    language,
    t,
    'challenge-card.duration-string.ongoing',
    `challenge-card.duration-string.flexible.days_icu`,
    `challenge-card.duration-string.flexible.weeks_icu`,
    'challenge-card.duration-string.no-limit',
  );

  const pricingString = serverToClientPricing(
    t,
    challenge as any,
    userPaidPlans,
    'challenge-card.pricing.free',
    'challenge-card.pricing.paid.separator',
    'pricing.payment-option.subscription_icu',
    'challenge.page.pricing-options.paid-general',
  );

  const showParticipants = !!(
    displayParams.participants &&
    challenge.participantsSummary.participantsNumber
  );

  const buttonLabel = settings.get(
    showParticipantButton
      ? settingsParams.buttonTextForParticipant
      : settingsParams.buttonText,
  );

  const media = challenge?.settings?.description?.media;
  const imgUrl = getMediaPreview(
    media,
    isMobile ? 320 : 800,
    isMobile ? 240 : 600,
  );

  let textAlignment: any;
  let opgTextAlignment: any;
  if (cssPBEnabled) {
    opgTextAlignment = classes.opgTextAlignment;
  } else {
    textAlignment = settings.get(settingsParams.textAlignment);
  }
  return (
    <section
      className={st(
        classes.card,
        {
          textAlignment,
          imageShape: settings.get(settingsParams.imageShape),
          cropSelection: settings.get(settingsParams.cropSelection),
          imageRatio: settings.get(settingsParams.imageRatio),
          imageLayoutType: settings.get(settingsParams.imageLayoutType),
          imageResize: settings.get(settingsParams.imageResizeOptions),
          mobile: isMobile,
          buttonState,
          even: cardIndex % 2 === 0,
        },
        opgTextAlignment,
      )}
    >
      {displayParams.image && (
        <div
          className={classes.media}
          style={getStyle({ displayVar: '--opgDisplayImage' })}
        >
          <a
            tabIndex={-1}
            aria-hidden="true"
            href={getProgramLink(challenge)}
            onClick={(e) => {
              e.preventDefault();
              goToPage(e);
            }}
          >
            <div className={classes.ratioBox}>
              {imgUrl ? (
                <img
                  alt={getProgramAltImageText(challenge)}
                  data-hook="image-wrapper"
                  className={classes.imageWrapper}
                  src={imgUrl}
                  loading={getLoadingStrategy(isMobile, cardIndex)}
                />
              ) : null}
            </div>
          </a>
        </div>
      )}
      <TextWithDirection>
        <div className={classes.info}>
          <ChallengeBadge
            isPrivate={isPrivateChallenge(challenge)}
            summary={summary}
            challengeTransition={challenge.transitions?.['0']?.state}
          />
          <div
            data-hook="info-wrapper"
            ref={cardRef}
            style={{ position: 'absolute', width: '100%' }}
          />
          <div className={classes.infoWrapper}>
            <a
              href={getProgramLink(challenge)}
              onClick={(e) => {
                e.preventDefault();
                goToPage(e);
              }}
            >
              <TitleTag
                className={classes.title}
                data-hook="card-title"
                id={`id-${challenge.id}`}
                title={isTitleExceedLimit ? title : null}
              >
                {(challenge as any)?.shouldTranslateTitle
                  ? t(challenge.settings.description.title)
                  : title}
              </TitleTag>
            </a>

            <ChallengeCardSubtitle
              displayParams={displayParams}
              showParticipants={showParticipants}
              durationString={durationString}
              challenge={challenge}
            />
            {displayParams.divider && displayParams.price ? (
              <div className={classes.priceWrapper}>
                {displayParams.divider && (
                  <div
                    data-hook="challenge-divider"
                    className={classes.divider}
                    style={getStyle({ displayVar: '--opgDisplayDivider' })}
                  />
                )}
                {displayParams.price && (
                  <p
                    data-hook="challenge-pricing"
                    className={classes.pricing}
                    style={getStyle({ displayVar: '--opgDisplayPrice' })}
                  >
                    {pricingString}
                  </p>
                )}
              </div>
            ) : null}
          </div>
          <div className={classes.buttonWrapper}>
            <Button
              aria-label={`${buttonLabel} ${challenge.settings.description.title}`}
              data-hook="view-button"
              as="a"
              href={getProgramLink(challenge)}
              fullWidth={isGridLayout && !isFullWidth}
              className={classes.button}
              priority={ButtonPriority.primary}
              size={ButtonSize.small}
            >
              {buttonLabel as string}
            </Button>
          </div>
        </div>
      </TextWithDirection>
    </section>
  );
};

ChallengeCard.displayName = 'ChallengeCard';
